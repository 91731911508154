import React from "react";
import Layout from "../../components/layout";

const AHighLevelGuideToEstatePlaning = () => {
  return (
    <Layout>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1 className="block  text-center text-customBlue font-semibold tracking-wide uppercase">
              A High-Level Guide To Estate Planning
            </h1>
            <p>
              By no means is this an extensive list. This guide is only meant to
              have you think about what is needed to create an estate plan. For
              local laws and detailed plans please consult an expert estate
              planner or attorney.
            </p>
            <h2 className="block  text-customBlue font-semibold tracking-wide">
              What is an estate plan?
            </h2>
            <p>
              An estate plan is a guide for your loved ones and trusted
              professionals to make sure that your wishes are fulfilled.
            </p>{" "}
            <p>
              The term estate planning refers to the preparation of tasks that
              serve to manage an individual&#39;s financial situation in the
              event of their incapacitation or death. The planning includes the
              settlement of assets to heirs and the settlement of estate
              taxes and debts, along with other considerations like the
              guardianship of minors and pets. Some of the steps included in
              estate planning typically include listing assets and debts,
              reviewing accounts, and the writing of wills. Simply put,
            </p>
            <h2 className="block  text-customBlue font-semibold tracking-wide">
              Do I need an estate plan?
            </h2>
            <p>Yes! Every adult should have an estate plan.</p>
            <h2 className="block  text-customBlue font-semibold tracking-wide">
              Why do I need an estate plan?
            </h2>
            <p>Among many other benefits, your estate plan can:</p>
            <ul className="list-disc">
              <li>Allow you to choose who inherits what.</li>
              <li>
                Reduce state decisions made without your wishes being honored.
              </li>
              <li>Name guardian(s) for your dependents.</li>
              <li>
                Reduce family stress and arguments following your passing.
              </li>
            </ul>
            <h2 className="block  text-customBlue font-semibold tracking-wide">
              How to make an estate plan ?
            </h2>
            <h3 className="block  text-customBlue font-semibold tracking-wide">
              Step 1: Do you need an expert?
            </h3>
            <p>
              Everyone can benefit from an expert, but you really need a
              professional if your estate has some of the following:
            </p>
            <ul className="list-disc">
              <li>Have a partner with whom you aren’t legally married.</li>
              <li>Have a divorce or custody agreement in place.</li>
              <li>
                Remarried or have a blended family — especially if you have
                children.
              </li>
              <li>
                Prefer that someone does (or doesn’t) get access to you at the
                hospital.
              </li>
              <li>Own multiple properties.</li>
            </ul>
            <h3 className="block  text-customBlue font-semibold tracking-wide">
              Step 2: Make a list of your possessions. (Physical and Digital)
            </h3>
            <p>
              Before you can decide what will happen to your possessions, you
              need to make sure that you are accounting for all of them.
            </p>
            <p>You should list everything of value:</p>
            <ul className="list-disc">
              <li>House</li>
              <li>Vehicles</li>
              <li>Bank Accounts</li>
              <li>Jewelry</li>
              <li>Social Media Accounts</li>
              <li>Money Transfer Apps</li>
              <li>Apple Account</li>
            </ul>
            <h3 className="block  text-customBlue font-semibold tracking-wide">
              Step 3: List your debts
            </h3>
            <p>Take an inventory all of your debts.</p>
            <ul className="list-disc">
              <li>Credit Cards</li>
              <li>Loans</li>
              <li>Store Cards</li>
              <li>Student Loans</li>
            </ul>
            <h3 className="block  text-customBlue font-semibold tracking-wide">
              Step 4: Update the beneficiaries on your retirement accounts and
              insurance policies.
            </h3>
            <p>
              Make sure you are regularly checking and updating the
              beneficiaries on your financial accounts and life insurance
              policies.
            </p>
            <h3 className="block  text-customBlue font-semibold tracking-wide">
              Step 5: Choose the guardians (if you have children)
            </h3>
            <p>
              The next step is to choose the guardians for your children. An
              guardian is a person who is selected to care for your children
              following your passing.
            </p>
            <h3 className="block  text-customBlue font-semibold tracking-wide">
              Step 6: Create a will.
            </h3>
            <p>
              Once you have your lists of assets and debts and your guardians in
              place, it is time to start creating the will in your estate plan.
            </p>
            <p>Your will should include:</p>
            <ul className="list-disc">
              <li>
                Assets and property - the property and assets you are passing on
                to your loved ones, from your 401(k) and your house deed to
                grandpa’s favorite watch.
              </li>
              <li>
                Beneficiaries - the people who will inherit your property and
                assets.
              </li>
              <li>
                Custodian/Trustee - an adult custodian who is named to manage
                money in a trust account on behalf of a beneficiary who is a
                minor.
              </li>
              <li>
                Executor - Someone to manage your will and close your estate. An
                executor is also called an administrator or personal
                representative
              </li>
              <li>
                Guardian - the person/people selected to take care of your
                kid(s) and pet(s).
              </li>
              <li>
                Instructions on finances, distribution of money, and paying
                debts.
              </li>
              <li>Additional funeral or burial instructions.</li>
              <li>Gifts to individuals or charities.</li>
            </ul>
            <h3 className="block  text-customBlue font-semibold tracking-wide">
              Step 7: Complete your estate plan.
            </h3>
            <p>
              With your will completed, you can work on creating the following
              documents:
            </p>
            <ul className="list-disc">
              <li>Power of Attorney</li>
              <li>Executive Power of Attorney</li>
              <li>Medical Power of Attorney</li>
              <li>Financial Power of Attorney</li>
              <li>A Living Will</li>
              <li>Last Will And Testament</li>
            </ul>
            <h3 className="block  text-customBlue font-semibold tracking-wide">
              Step 8: Share your estate documents with your trusted contacts.
            </h3>
            <p>
              Your estate plan documents are finished. You have made it to the
              last step. Now, it is time to make sure that the people you have
              chosen to manage your affairs have the information they need to do
              so. By uploading your information in TrustLoKR and sharing this
              information with your trusted contacts, you can be assured that
              the right people of all the information they need to execute your
              wishes.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AHighLevelGuideToEstatePlaning;
